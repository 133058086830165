#site-footer {
    font: $font-footer;
    &.fixed {
        position: fixed;
        bottom:0;
        left:0;
        right:0;
        .footer-btn-wrapper {
            position:absolute;
            bottom:auto;
            top:-68px;
        }
        .black-wrapper {
        }
    }
    .footer-btn-wrapper {
        font-size: 12px;
        display: inline-block;
        height: 68px;
        line-height: 68px;
        transition: all 0.25s;
        width:68px;
        position: fixed;
        left:50%;
        bottom:0;
        margin-left:-34px;
        z-index:999;
        &:hover {
            font-size: 24px;
        }
        .footer-btn {
            transition: all 0.25s;
            @include media-breakpoint-down(sm) {
                display: none;
            }
            &.hidden {
                opacity:0;
            }
        }
    }
    &.white-text {
        .footer-btn {
            color:$color-white;
        }
    }
    .black-wrapper {
        position:relative;
        z-index:1000;
        background-color: $black;
        color:$white;
        a {
            color:inherit;
        }
        .fa {
          display: inline-block;
          font-size: 28px;
          padding: 7px;
          border: 2px solid #fff;
          border-radius: 50%;
          width: 47px;
          height: 47px;
          text-align: center;
          margin: 2px 7px;
        }
        .container {
            padding-top: 57px;
            padding-bottom: 57px;
            @include media-breakpoint-only(md) {
                .col-md-6:nth-child(3), .col-md-6:nth-child(4) {
                    padding-top: $line-height-footer;
                }
                .col-md-6.text-right {
                    text-align: left!important;
                }
            }
            @include media-breakpoint-down(sm) {
                .col-lg-3+.col-lg-3 {
                    padding-top: $line-height-footer;
                }
            }
        }
    }
    #mc-embedded-subscribe-form {
        padding-top: $line-height-footer;
        input {
            color:inherit;
            background-color: inherit;
            appearance: none!important;
            font-size: inherit;
            font-family: inherit;
            &:focus {
                outline: none;
                border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder, &::placeholder {
                color: red;
            }
            &.email {
                border:1px solid $color-white;
                width:100%;
                padding: 6px 10px 5px 10px;
            }
            &.subscribe {
                border:none;
                padding:7px 0 0 0;
                cursor: pointer;
            }
        }
        #mce-responses, div.mce_inline_error {
            font: $font-body;
            padding-top:10px;
        }
    }
}
input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      /* Firefox 18- */
      color: #f9de00;
      opacity: 1; }
