// Custom Mixins

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centered-content {
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

@mixin horizontal-center {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
}
