// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
) !default;
$border-width: 1px !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Colors
//
// Color settings

$color-black: #000;
$color-white: #FFF;

// Header
//
// Settings for the `<header>` element.

$header-height: 95px;
$header-height-mobile: 54px;

// Body
//
// Settings for the `<body>` element.

$body-bg:       #ffffff !default;

// Links
//
// Style anchor elements.

$link-color:            #000000 !default;
$link-decoration:       none !default;
$link-hover-color:      #000000 !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 768px,
  md: 959px,
  lg: 960px,
  xl: 1180px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-width-base:     28px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Swiss721BT-BoldExtended", Arial, sans-serif !default;
$font-family-serif:      "SwissWorks", "Times New Roman", Times, serif !default;
$font-family-standard:   sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-serif !default;

$font-size-body: 17px!default;
$font-size-h1:(70/17) * 1em!default;
$font-size-h1-big:(100/17) * 1em!default;
$font-size-h2:(40/17) * 1em!default;
$font-size-h3:(30/17) * 1em!default;
$font-size-nav:(25/17) * 1em!default;
$font-size-footer:(20/17) * 1em!default;
$font-size-lead:(40/17) * 1em!default;
$font-size-subnav:(20/17) * 1em!default;
$font-size-masonry-item:$font-size-nav!default;
$font-size-readmore:$font-size-nav!default;

$line-height-body:(25/17) * 1em!default;
$line-height-h1:(84/70) * 1em!default;
$line-height-h1-big:(100/100) * 1em!default;
$line-height-h2:(48/40) * 1em!default;
$line-height-h3:(38/30) * 1em!default;
$line-height-nav:(29/25) * 1em!default;
$line-height-footer:(24/20) * 1em!default;
$line-height-subnav:(25/20) * 1em!default;
$line-height-masonry-item:$line-height-nav!default;
$line-height-readmore:$line-height-nav!default;

$font-factor-sm:0.75!default;
$font-size-h1-sm:$font-size-h1 * $font-factor-sm!default;
$font-size-h1-big-sm:$font-size-h1-big * $font-factor-sm!default;
$font-size-h2-sm:$font-size-h2 * $font-factor-sm!default;
$font-size-h3-sm:$font-size-h3 * $font-factor-sm!default;
$font-size-nav-md: $font-size-nav * $font-factor-sm!default;
$font-size-masonry-item-sm:$font-size-body!default;
$font-size-readmore-sm:$font-size-body!default;
$font-size-lead-sm:$font-size-lead * $font-factor-sm!default;

$font-factor-xs:0.5!default;
$font-size-body-xs:15px!default;
$font-size-h1-xs:$font-size-h1 * $font-factor-xs!default;
$font-size-h1-big-xs:$font-size-h1-big * $font-factor-xs!default;
$font-size-h2-xs:$font-size-h2 * $font-factor-xs!default;
$font-size-lead-xs:$font-size-lead * $font-factor-xs!default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-body: $font-weight-normal $font-size-body #{"/"} $line-height-body $font-family-serif!default;
$font-h1: $font-weight-normal $font-size-h1 #{"/"} $line-height-h1 $font-family-sans-serif!default;
$font-h2: $font-weight-normal $font-size-h2 #{"/"} $line-height-h2 $font-family-sans-serif!default;
$font-nav: $font-weight-normal $font-size-nav #{"/"} $line-height-nav $font-family-sans-serif!default;
$font-footer: $font-weight-normal $font-size-footer #{"/"} $line-height-footer $font-family-sans-serif!default;
$font-lead: $font-h2!default;
$font-bold: $font-weight-normal $font-size-body #{"/"} $line-height-body $font-family-sans-serif!default;
$font-subnav: $font-weight-normal $font-size-subnav #{"/"} $line-height-subnav $font-family-sans-serif!default;
$font-masonry-item: $font-weight-bold $font-size-masonry-item #{"/"} $line-height-masonry-item $font-family-serif!default;
$font-readmore: $font-weight-bold $font-size-readmore #{"/"} $line-height-readmore $font-family-sans-serif!default;
$font-arrow: $font-weight-normal $font-size-h1 #{"/"} 1em $font-family-standard!default;

/* custom vars */
$section-padding-top: 170px !default;
$section-padding-top-small: 100px !default; // vorher 70px
$section-padding-bottom-small: 100px !default; // vorher 70px
$section-padding-bottom: 120px !default;

$section-padding-top-md: 80px !default;
$section-padding-bottom-md: 80px !default;
