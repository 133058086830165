// Navigation Styles
#site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index:1000;
    nav {
        @include media-breakpoint-up(sm) {

        }
        .main-nav {
            width: 100%;
            padding: 26px 0;
            ul {
                list-style-type: none;
                margin: 0;
                padding:0;
                > li {
                    display: block;
                    a {
                        font: $font-nav;
                        text-transform: uppercase;
                        display: inline-block;
                        position: relative;
                        //z-index: 1035;
                        letter-spacing: 0.05em;
                        @include media-breakpoint-down(lg) {
                            font-size: $font-size-nav-md;
                        }
                        &[title="Home"] {
                            img, svg {
                                display: block;
                                &:first-child {
                                    height:44px;
                                }
                                &+img, &+svg {
                                    margin-top: 8px;
                                    position: absolute;
                                    height:70px;
                                }
                            }
                        }
                    }
                    &.active {
                        a {
                            border-bottom: 2px solid #000;
                            &[title="Home"] {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        //Mobile Nav
        .mobile-nav {
            width: 100%;
            .mobile-nav-trigger {
                cursor: pointer;
                padding: 15px 0;
                img, svg {
                    display: block;
                    margin: 0 auto;
                }
            }
            .mobile-nav-menu {
                display: none;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding:0;
                    > li {
                        display: block;
                        text-align: center;
                        & + li {
                            padding-top: 15px;
                        }
                        a {
                            font: $font-nav;
                            text-transform: uppercase;
                            display: inline-block;
                            letter-spacing: 0.05em;
                        }
                        &.active {
                            a {
                                border-bottom: 2px solid #000;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open {
        background-color: black;
        height: 100vh;
        nav {
            .mobile-nav {
                svg .cls-1 {
                    fill: $color-white;
                }
                .mobile-nav-menu {
                    display: block;
                    ul > li {
                        a {
                            color: $color-white;
                        }
                        &.active {
                            a {
                                border-bottom: 2px solid $color-white;
                            }
                        }
                    }
                }
            }
        }
    }

    // ScrollMagic Classes
    &.white-text {
        svg .cls-1 {
            fill: $color-white;
        }
        nav .main-nav ul > li,
        nav .mobile-nav ul > li {
            a {
                color: $color-white;
            }
            &.active {
                a {
                    border-bottom: 2px solid $color-white;
                }
            }
        }
    }
    &.nav-hidden {
        .main-nav {
            a {
                opacity:0;
                visibility: hidden;
                transition: all 0.25s;
                &[title="Home"] {
                    opacity:1;
                    visibility: visible;
                    img, svg {
                        &+img, &+svg {
                            opacity:0;
                            visibility: hidden;
                            transition: all 0.25s;
                        }
                    }
                }
            }
        }
        &.mouse-over {
            a {
                opacity:1;
                visibility: visible;
            }
        }
    }
    /*&.fixed_notvisible {
        .main-nav {
            a {
                opacity:0;
            }
        }
    }*/


}
