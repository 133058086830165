// CONTENT PAGE STYLES
.page-content {
    h1 {
        margin-top:-10px;
    }

    a {
        text-decoration: underline;
    }

    iframe {
        border: none;
    }

    // Seiten mit schwarzem Hintergrund
    &.black {
        background-color: #000;
        color: #fff;
        a {
            color: #fff;
        }
    }

    .read-more, .subnav a {
        font: $font-bold;
        text-transform: uppercase;
        text-decoration: none;
    }
    .subnav {
        font:$font-subnav;
        padding-bottom:26px;
    }
    ul.subnav {
        text-align: center;
        padding:0 0 26px 0;
        margin:0;
        li {
            display:inline-block;
            padding:0 30px;
            &.active a {
                border-bottom: 2px solid $color-black;
            }
        }
    }
}

.linked-content {
    cursor: pointer;
}

.lead {
    font: $font-lead;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
        font-size:$font-size-lead-sm;
    }
    @include media-breakpoint-down(xs) {
        font-size:$font-size-lead-xs;
    }
    .read-more-p {
        margin-top: $line-height-readmore;
        font-size:$font-size-body;
    }
    .read-more {
        font: $font-readmore;
        @include media-breakpoint-down(sm) {
            font-size:$font-size-readmore-sm;
        }
        .txt-less {
            display:none;
        }
        &.active {
            .txt-more {
                display:none;
            }
            .txt-less {
                display:inline;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h2-sm;
    }
}

.more-text {
    display:none;
}

.arrow {
    font: $font-arrow;
    text-decoration: none !important;
}
.arrow.back {
    margin-left:-0.1em;
}
.next-link {
    @extend .arrow;
    cursor: pointer;
    transition: all 0.25s;
    .link-hidden & {
        opacity:0;
        visibility: hidden;
    }
}
#section-nav {
    position: fixed;
    left:6px;
    top:0;
    bottom:0;
    width:22px;
    line-height:16px;
    a {
        font-size: 12px;
        transition: font-size 0.25s;
        color:#AAA;
        &:hover {
            font-size: 24px;
        }
        &.active {
            color:#000;
        }
        .home & {
            color:#aad8de;
            &.active {
                color:#3e9cab;
            }
        }
        .ueber-uns & {
            color:#b9d7f2;
            &.active {
                color:#3469b0;
            }
        }
        .catering & {
            color:#f0d6dc;
            &.active {
                color:#a2393e;
            }
        }
    }
}
.infinite-loader {
    display:inline-block;
    width:24px;
    height:24px;
    position: absolute;
    bottom:-24px;
    left:50%;
    margin-left:-12px;
    opacity:0;
    transition: all 0.25s;
    background:
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"><style>.cls-1{fill:#000000;}</style><g><polygon class="cls-1" points="12.21 0 9.16 9.11 0 9.36 7.27 15.23 4.67 24.49 12.21 19.01 19.76 24.49 17.15 15.23 24.42 9.36 15.27 9.11 12.21 0"></polygon></g></svg>') center center no-repeat;
    &.white {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"><style>.cls-1{fill:#FFFFFF;}</style><g><polygon class="cls-1" points="12.21 0 9.16 9.11 0 9.36 7.27 15.23 4.67 24.49 12.21 19.01 19.76 24.49 17.15 15.23 24.42 9.36 15.27 9.11 12.21 0"></polygon></g></svg>');
    }
    &.active {
        opacity:1;
        animation: spin 1s infinite linear;
        -webkit-animation: spin2 1s infinite linear;
    }
}
@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
.sliding-cols {
    overflow:hidden;
    .slide-in {
        opacity: 0;
        transition: transform 2s cubic-bezier(0, 0.99, 0.2, 1), opacity 500ms;
        transform: translateY(100%);
        overflow: hidden;

        &.visible {
            transform: none;
            opacity: 1;
        }
    }
}
