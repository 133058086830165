body{
    letter-spacing: 0.01em;
    font: $font-body;
    color: #000;
    overflow-y: scroll;
    @include media-breakpoint-down(xs) {
        font-size: $font-size-body-xs;
    }
}

a {
    color: $link-color;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: $link-color;
        text-decoration: none;
    }
}

b, strong {
    font: $font-bold;
    text-transform: uppercase;
}

p {
    margin-bottom:0;
    &+p, &+h2 {
        padding-top: $line-height-body;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1 {
    font: $font-h1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h1-sm;
    }
    @include media-breakpoint-down(xs) {
        font-size: $font-size-h1-xs;
    }
}

h2 {
    font:$font-h2;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    + p {
        padding-top: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h2-sm;
    }
    @include media-breakpoint-down(xs) {
        font-size: $font-size-h2-xs;
    }
}

h3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.05em;
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h3-sm;
    }
}

h4 {
    font-size: 20px;
    line-height: 25px;
}

img {
    max-width: 100%;
    height: auto;
}

ul {

}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      	position: absolute;
      	top: 0;
      	left: 0;
      	width: 100%;
      	height: 100%;
    }
}
input {
    &::-webkit-input-placeholder {
        color: inherit;
    }
    &::-moz-placeholder {
        color: inherit;
        opacity: 1;
    }
    &:-ms-input-placeholder {
      color: inherit;
    }
    &:-moz-placeholder {
      color: inherit;
    }
}

#tinymce {
    a {
        text-decoartion: underline;
    }
}
