// Gastronomie Styles
.gastronomie {
    .gastronomie-item {
        @include media-breakpoint-down(md) {
            div + div {
                padding-top: 1em;
            }
            h2 + p {
                padding-top: 1em;
            }
        }
        @include media-breakpoint-down(sm) {
            h2 + p,
            p + p {
                padding-top: 0.5em;
            }
        }
        @include media-breakpoint-down(xs) {
            h2 + p,
            p + p {
                padding-top: 0.1em;
            }
        }
    }
    .detail-view {

    }

}
