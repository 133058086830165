// Über uns Styles
.ueber-uns {
    .section-header {
        height: 200px;
    }
}
.partner-item {
    .partner-img {
        padding-bottom: 1.2em;
    }
    h2 {
        text-transform: none;
        letter-spacing: 0.05em;
        padding-bottom: 2px;
    }
    h2 + p {
        padding-top: 0;
    }
    padding-bottom: 2.5em;
}
