// Home Styles

.home {
    .page-title {
        h1 {
            font-size: $font-size-h1-big;
            line-height: $line-height-h1-big;
            @include media-breakpoint-down(sm) {
                font-size: $font-size-h1-big-sm;
            }
            @include media-breakpoint-down(xs) {
                font-size: $font-size-h1-big-xs;
            }
            em {
                font-family:$font-family-serif;
                font-style: normal;
                font-weight: normal;
            }
        }
    }
}
