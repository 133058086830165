// LAYOUT GENERAL STYLES

html, body, #page, section {
    max-width:100%;
}
html, body {
    overflow-x: hidden;
    overflow-y: auto;
}


#page {
    min-height: 100vh;
    &.black {
        background-color: #000;
        color: #fff;
        a {
            color: #fff;
        }
    }
}

// Container
.container {
    margin: 0 auto;
    @include media-breakpoint-down(md) {
        padding-left:28px;
        padding-right:28px;
    }
    @include media-breakpoint-down(sm) {
        padding-left:20px;
        padding-right:20px;
    }
}

// Section Header
.page-header {
    position: relative;
    .page-title {
        &+.row {
            padding-top:0;
        }
        h2 {
            text-align: center;
        }
    }
}
// fullscreen section
.full {
    min-height: 100vh;
    padding:$section-padding-top 0;
    @include centered-content;

    @include media-breakpoint-down(md) {
        padding:$section-padding-top-md 0;
    }

    .next-link-row {
        position: absolute;
        width:100%;
    }

    &+section.page-content {
        margin-top: -$section-padding-top;
        @include media-breakpoint-down(md) {
            margin-top: -$section-padding-top-md;
        }
    }
}
// Section Content
.page-content {
    padding-top: $section-padding-top;
    @include media-breakpoint-down(md) {
        padding-top: $section-padding-top-md;
    }
    .section-title {
        &+.row {
            padding-top: 2em;
        }
    }
    &+.black {
        margin-top:170px;
        padding: $section-padding-top-small 0 $section-padding-bottom-small 0;
        @include media-breakpoint-down(md) {
            padding: $section-padding-top-md 0 $section-padding-bottom-md 0;
        }
    }

    &.detail-view {
        .header-image {
            position: relative;
            h1 {
                @include centerer;
                color: #ffffff;
                text-align: center;
                width:90%;
            }
        }
    }
}
section.last-section, section.black.last-section {
    padding-bottom:$section-padding-bottom;
    @include media-breakpoint-down(md) {
        padding-bottom:$section-padding-bottom-md;
    }
}

.center-verticaly {
    margin-top: auto!important;
    margin-bottom: auto!important;
    flex: 0 0 100%;
}
//Rows
.row + .row {
    padding-top: 3em;
}
// Cols
.col,
.col-sm-12 {
    &.no-padding {
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    &.full-height {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.full-width {
        padding-right: 0;
        padding-left: 0;
    }
}

// Layout Special Styles
#impressum {
    min-height: 100vh;
}
