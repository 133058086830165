// Aktuell Styles
.news {
    .page-image {
        position: absolute;
        right:10%;
        top:350px;
    }
    .news-item {
        padding-bottom: 2.5em;
        .news-image + .news-text {
            padding-top: 1.2em;
        }
        .news-text {
            h2 {
                font: $font-footer;
            }
            p {
                padding-top: 0;
            }
        }
    }
}
