.parallax-imgs {
    position: absolute;
    z-index: -1;
    padding:0 30px;
    box-sizing: content-box;
    img {
        position: absolute;
    }
}

// Home Intro
#parallax-home {
    width:526px;
    height:230px;
    left: 0;
    top: 250px;
    @include media-breakpoint-down(md) {
        left: 0;
    }
}
// Über uns Leitbild
#parallax-leitbild {
    width:227px;
    height:69px;
    left: 17.5%;
    top: 90px;
    @include media-breakpoint-down(md) {
        left: 5px;
    }
}
// Über uns Geschichte
#parallax-geschichte {
    width:238px;
    height:189px;
    left: 70%;
    top: -90px;
    @include media-breakpoint-down(md) {
        left: auto;
        right:10px;
    }
}

// Catering Intro
#parallax-catering {
    width:308px;
    height:254px;
    left:0;
    top: 13px
}
// Gastronomie
#parallax-gastronomie {
    width:299px;
    height:279px;
    left: 83%;
    top: 235px;
    // Extra breakpoint, prevent image overlapping viewport
    @media (max-width: 1430px) {
        left:74%;
    }
    @include media-breakpoint-down(lg) {
        left:auto;
        right:-20px;
    }
}
