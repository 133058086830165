@import url("//hello.myfonts.net/count/33a3c8");

@font-face {
    font-family: 'Swiss721BT-BoldExtended';
    src: url('../fonts/swiss721/33A3C8_0_0.eot');
    src: url('../fonts/swiss721/33A3C8_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/swiss721/33A3C8_0_0.woff2') format('woff2'),url('../fonts/swiss721/33A3C8_0_0.woff') format('woff'),url('../fonts/swiss721/33A3C8_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'SwissWorks';
    src: url('../fonts/suisse-works/SuisseWorks-Regular-WebS.eot');
    src: url('../fonts/suisse-works/SuisseWorks-Regular-WebS.eot?#iefix') format('embedded-opentype'),url('../fonts/suisse-works/SuisseWorks-Regular-WebS.woff2') format('woff2'),url('../fonts/suisse-works/SuisseWorks-Regular-WebS.woff') format('woff'),url('../fonts/suisse-works/SuisseWorks-Regular-WebS.ttf') format('truetype');
}

@font-face {
    font-family: 'SwissWorks';
    src: url('../fonts/suisse-works/SuisseWorks-Bold-WebS.eot');
    src: url('../fonts/suisse-works/SuisseWorks-Bold-WebS.eot?#iefix') format('embedded-opentype'),url('../fonts/suisse-works/SuisseWorks-Bold-WebS.woff2') format('woff2'),url('../fonts/suisse-works/SuisseWorks-Bold-WebS.woff') format('woff'),url('../fonts/suisse-works/SuisseWorks-Bold-WebS.ttf') format('truetype');
    font-weight: bold;
}
