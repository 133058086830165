.masonry {
    .grid-sizer {
        &+.grid-item {
            margin-top:0!important;
        }
    }
    .grid-item {
        @include media-breakpoint-down(sm) {
            margin:29px 0 0 0!important;
        }
        p {
            font:$font-masonry-item;
            @include media-breakpoint-down(sm) {
                font-size:$font-size-masonry-item-sm;
            }
        }
        img {
            width:100%;
        }
    }
}
